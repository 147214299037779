export const cellContainer = ({ theme: { colors }, isFirst }) => ({
    borderTop: `0.0625rem solid ${colors.lightGrey}`,
    paddingTop: '2.5rem',
    paddingBottom: '2.5rem',
    position: 'relative',

    ...(isFirst && {
        '&:before': {
            content: '""',
            width: '1rem',
            height: '0.3125rem',
            backgroundColor: colors.fuchsia,
            position: 'absolute',
            top: 0,
        },
    }),
});

export const extendText = () => ({
    fontSize: '1.125rem',
});
