import React from 'react';

import { Layout } from '../modules/ui';
import ContactPageComponent from '../modules/contact/ContactPage';
import Seo from '../components/SEO';
import * as Constants from '../constants';

const ContactPage = () => {
    return (
        <>
            <Seo pageId={Constants.pages.CONTACT} />
            <Layout>
                <ContactPageComponent />
            </Layout>
        </>
    );
};

export default ContactPage;
