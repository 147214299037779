import React from 'react';
import loadable from '@loadable/component';
import { FormattedMessage } from 'react-intl';

import { BookCallSection } from 'modules/inquiry';
import { Hero } from 'modules/hero-section/Hero';

import SidebarContent from './SidebarContent';

const FormSection = loadable(() => import('../../inquiry/FormSection'));

const ContactPage = () => (
    <>
        <Hero title={<FormattedMessage id="contactPage.title" />} noPaddingBottom />
        <BookCallSection />
        <FormSection
            title="contactPage.title"
            perex={<FormattedMessage id="contactPage.perex" />}
            sidebarContent={<SidebarContent />}
            submitButtonEventId="submit-contact"
            formEventId="contact-form-contact"
        />
    </>
);

export default ContactPage;
