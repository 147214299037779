import fill from 'lodash/fill';
import { IS_DE_LANG_ENV } from '../../../../config/env';

export const ids = Array.from(Array(3).keys());

export const rows = [
    fill(Array(2)).map((_, index) => `contactPage.sidebar.cell1.line${index + 1}`),
    fill(Array(IS_DE_LANG_ENV ? 4 : 3)).map((_, index) => `contactPage.sidebar.cell2.line${index + 1}`),
    fill(Array(IS_DE_LANG_ENV ? 2 : 3)).map((_, index) => `contactPage.sidebar.cell3.line${index + 1}`),
];
