import React from 'react';
import { PropTypes } from 'prop-types';
import * as UI from '../../../ui';
import { FormattedMessage } from 'react-intl';
import { FelaComponent } from 'react-fela';

import { rows } from './content';

const { DynamicContainer, Text, SocialNetworks } = UI;

const SidebarContent = ({ rules }) => (
    <DynamicContainer startAt={3} columns={10}>
        {rows.map((row, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <FelaComponent as="div" isFirst={index === 0} key={index} style={rules.cellContainer}>
                {row.map(item => (
                    <Text key={item} extend={{ text: rules.extendText }}>
                        <FormattedMessage id={item} />
                    </Text>
                ))}
            </FelaComponent>
        ))}
        <SocialNetworks inContact />
    </DynamicContainer>
);

SidebarContent.propTypes = {
    rules: PropTypes.shape().isRequired,
};

export default SidebarContent;
